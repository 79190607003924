<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">九宫格中奖人员</h3>
    </div>
    <div class="box">
      <div class="top">
        <span style="font-size: 14px; margin-left: 10px">奖品名称:</span>
        <el-input v-model="from.username" placeholder="请输入奖品名称"></el-input>
        <span style="font-size: 14px; margin-left: 10px">中奖人手机号:</span>
        <el-input v-model="from.phone" placeholder="请输入中奖人手机号"></el-input>
          <span class="orderTime">中奖时间: </span>
          <el-date-picker
            v-model="TimeValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
          >
          </el-date-picker>
        <span
          class="addshop shou"
          style="
            margin-left: 10px;
            padding: 5px 15px;
            background-color: #0970cc;"
          @click="shaixuan"
          >筛选</span>
        <span
          class="addshop shou"
          style="
            margin-left: 10px;
            padding: 5px 15px;
            background-color: rgb(252, 85, 49);
            color: #FFFFFF;"
          @click="handleExport"
          >导出</span>
      </div>
      <!-- <span style="color: #000; margin-bottom:10px; display:block">提现列表:</span> -->
      <el-table
        :data="list"
        stripe
        border
        :header-row-style="{ height: '60px' }"
        :row-style="{ height: '30px' }"
        style="width: 90%"
      >
        <el-table-column prop="name" label="中奖商品"> </el-table-column>
         <el-table-column prop="createtime" label="中奖时间"> </el-table-column>
        <el-table-column prop="username" label="中奖人姓名">
        </el-table-column>
        <el-table-column prop="user_phone" label="中奖人手机号"> </el-table-column>
        <el-table-column prop="store_name" label="所属门店">
        </el-table-column>
        <el-table-column prop="status_name" label="状态">
            <!-- <template slot-scope="scope">

            </template> -->
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="crtChg"
        :current-page="from.page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
	<!-- 导出弹窗 -->
    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportShop2">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { lotterywinList, shopCapital, shopCheck, shopRemit, txOrderDetail } from "@/api/api.js";
import { filenameFormat } from "@/utils/index";
export default {
  components: {},
  name: "lotterywinList",
  data() {
    return {
      from: {
        page: 1,
        limit: 10, //页码
        user_id: '', //用户id
        username: '', //用户名
        name: '', //商品名称
        start: '', //中奖开始时间
        end: '', //中奖结束时间
        order: '', //是否生成订单 1是 2否  
        lottery_id: '', //奖项id
        phone: '', //电话
        export: "",
        export_type: "",
        // status: "", // 状态
        // name: "", // 店主
        // cellphone: "", // 电话
        // start_time: "", //  开始时间
        // end_time: "", //  结束时间
        // page: 1, // 页码
        // check_start_time: "",
        // check_end_time: "",
        // store_id: ''
      },
      username: "",
      shopTiXianId: null,
      TimeValue: null,
      checkTime: null,
      dialogShenHeShow: false,
      list: [],
      total: 10,
      exportShow: false,
      exportType: 1,
      withdrawDetailShow: false, //提现明细弹窗控制
      withdrawDetailList: [], //提现明细列表
      withdrawDetailpage: 1, //页码
      withdrawDetailTotal: 0, //总条数
      detailFrom:{
        id: '',
        export: '',
        export_type: '',
        page: 1,
      }
    };
  },
  created() {
    this.getlist();
  },
  watch: {
    // checkTime: function (newVal, oldVal) {
    //   if (newVal != null) {
    //     this.from.check_start_time = this.$timeTo.time4(this.checkTime[0]);
    //     this.from.check_end_time = this.$timeTo.time4(this.checkTime[1]);
    //   } else {
    //     this.from.check_start_time = "";
    //     this.from.check_end_time = "";
    //   }
    // },
    TimeValue: function (newVal, oldVal) {
      if (newVal != null) {
        this.from.start = this.$timeTo.time4(this.TimeValue[0]);
        this.from.end = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.from.start = "";
        this.from.end = "";
      }
    }
  },
  methods: {
    crtChg(e) {
      this.from.page = e;
      this, this.getlist();
    },
    exportShows() {
      this.exportShow = false;
    },
    exportShop2() {
      this.from.export = "export";
      this.from.export_type = this.exportType;
      this.getlist();
      this.from.export = "";
    },
    // 详情列表导出
    async detailexport(){
      if(!this.detailFrom.export_type){
       return this.$message.error('请选择导出范围');
      }
      this.detailFrom.export = 'export'
      let { data, types, request } = await txOrderDetail(this.detailFrom, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition").split("=")[1]
        // );
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
    },
    handleExport () {
      this.exportShow = true;
    },
    async getlist() {
      if (this.exportShow) {
        let { data, types, request } = await lotterywinList(this.from, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition").split("=")[1]
        // );
        this.exportShow = false;
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
      } else {
        const { data } = await lotterywinList(this.from);
        if (data.code != 200) return this.$message.error(data.data);
        this.list = data.data.list.data;
        this.total = data.data.list.total;
        console.log(this.list);
//         this.list.forEach((item) => {
//           item.pay_time = this.$timeTo.time3(item.pay_time);
//         });
      }
    },
    shaixuan() {
      this.exportShow = false;
      this.from.page = 1;
      this.getlist();
    },
    shenhe(id, name) {
      console.log(name);
      this.username = name;
      this.shopTiXianId = id;
      this.dialogShenHeShow = true;
    },
    async TXsuccess() {
      const { data } = await shopCheck({ id: this.shopTiXianId, status: 2 });
      this.dialogShenHeShow = false;
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
    },
    async TXerror() {
      const { data } = await shopCheck({ id: this.shopTiXianId, status: 3 });
      this.dialogShenHeShow = false;
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
    },
    remit(id) {
      this.$confirm("您是否要真的要打款吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          const { data } = await shopRemit({ id });
          if (data.code != 200) return this.$message.error(data.data);
          this.getlist();
          this.$message({
            type: "success",
            message: "付款成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  // 门店提现明细,列表数据
    async lookDetail(id){
      this.detailFrom.export_type = ''
      this.withdrawDetailShow = true
      this.detailFrom.id = id
       const { data } = await txOrderDetail(this.detailFrom);
      if (data.code != 200) return this.$message.error(data.data);
      this.withdrawDetailList = data.data.data
      this.withdrawDetailTotal = data.data.total
      console.log('data======',data)
    },
    // 翻页
    withdrawDetailCrtChg(e){
      this.detailFrom.page = e
      this.lookDetail(this.detailFrom.id)
    },
  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.box {
  .top {
    background-color: #f1f3f4;
    margin: 0 0 20px 0;
    border-radius: 5px;
    box-shadow: 4px 4px 3px #e9ebec;
    .el-input {
      width: 300px;
      margin: 10px;
      .el-input__inner {
        height: 32px;
      }
    }
    .addshop {
      padding: 5px 10px;
      color: #f1f1f1;
      border-radius: 3px;
      transition: font-size 0.2s;
    }
    .time {
      padding: 10px;
      display: inline-block;
    }
  }
  .shenhe {
    span {
      color: #555;
      font-size: 14px;
      &:last-child {
        border-bottom: 1px solid #555;
        &:hover {
          color: #111;
          border-bottom: 1px solid #111;
        }
      }
    }
  }
}
.dauan {
  color: #555;
  font-size: 14px;
  margin: 12px;
  &:hover {
    color: #111;
    border-bottom: 1px solid #111;
  }
}
.txbtn {
  margin-top: 50px;
  display: flex;
}
.el-dialog {
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
</style>